<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div v-else class="profile-hub-export">
      <UiBanner
        :title="$t('profilehub.export.title')"
        :subtitle="currentSegmentName ? currentSegmentName : $t('profilehub.export.subtitle')"
        large
      />
      <UiContainer large with-actions>
        <div class="tw-mb-8">
          <UiLabel id="segment" :label="$t('profilehub.export.modal.settings.label.segment')" />
          <v-select
            v-model="segment"
            id="segment"
            solo
            flat
            outlined
            :items="computedSegments"
            item-text="name"
            :return-object="true"
            :placeholder="$t('profilehub.export.modal.settings.placeholder.segment')"
            hide-details
            @input="updateCurrentProfiles"
          />
        </div>
        <v-alert type="info" color="primary" class="profile-hub-export__summary">
          <div>
            {{
              $t('profilehub.export.summary', {
                count: totalProfiles,
              })
            }}
          </div>
          <v-spacer />
          <v-progress-circular
            v-if="updatingContacts"
            indeterminate
            :size="24"
            :width="3"
            class="tw-absolute tw-right-4 tw-top-4"
          ></v-progress-circular>
        </v-alert>
        <section class="profile-hub-export__section profile-hub-export__section--first">
          <UiTitle :title="$t('profilehub.export.modal.settings.title')" />
          <UiSubtitle :subtitle="$t('profilehub.export.modal.settings.subtitle')" />

          <div class="profile-hub-export__section__grid">
            <div>
              <UiLabel id="name" :label="$t('profilehub.export.modal.settings.label.name')" />
              <v-text-field
                v-model="name"
                id="name"
                solo
                flat
                outlined
                :placeholder="$t('profilehub.export.modal.settings.placeholder.name')"
                hide-details
              />
            </div>
            <div>
              <UiLabel id="format" :label="$t('profilehub.export.modal.settings.label.format')" />
              <v-select
                v-model="format"
                id="format"
                solo
                flat
                outlined
                :items="fileFormats"
                item-value="value"
                :placeholder="$t('profilehub.export.modal.settings.placeholder.format')"
                hide-details
              />
            </div>
          </div>
        </section>

        <section class="profile-hub-export__section">
          <UiTitle :title="$t('profilehub.export.modal.content.title')" />
          <UiSubtitle :subtitle="$t('profilehub.export.modal.content.subtitle')" />

          <v-switch
            v-model="optin"
            :label="$t('profilehub.export.content.label.optin')"
            class="tw-p-0 tw-mt-6"
            hide-details
          ></v-switch>

          <v-checkbox
            v-model="allCriteria"
            :label="$t('profilehub.export.modal.content.label.allCriteria')"
            class="tw-mt-6 tw-p-0"
            hide-details
            @change="allCriteriasSelectToggle"
          >
          </v-checkbox>

          <UiLabel id="criteria" :label="$t('profilehub.export.modal.content.label.criteria')" class="tw-mt-3" />
          <v-autocomplete
            v-model="allCriteriaList"
            solo
            flat
            outlined
            id="criteria"
            :items="criterias"
            :label="$t('profilehub.export.modal.content.placeholder.criteria')"
            :placeholder="$t('profilehub.export.modal.content.placeholder.criteria')"
            item-text="label"
            item-value="label"
            hide-details
            chips
            clearable
            deletable-chips
            multiple
            :disabled="allCriteria"
          />
        </section>

        <section
          class="profile-hub-export__section"
          :class="{ 'profile-hub-export__section--with-margin': !passwordEnabled }"
        >
          <UiTitle :title="$t('profilehub.export.security.title')" />
          <UiSubtitle :subtitle="$t('profilehub.export.security.subtitle')" />

          <v-switch
            v-model="passwordEnabled"
            :label="$t('profilehub.export.security.label.passwordToggle')"
            class="tw-p-0 tw-mt-6"
            hide-details
            @change="password = ''"
          ></v-switch>
          <div v-if="passwordEnabled">
            <UiLabel id="passwsord" :label="$t('profilehub.export.security.label.password')" class="tw-mt-3" />
            <v-text-field
              v-model="password"
              id="passwsord"
              :error-messages="passwordError"
              solo
              flat
              outlined
              :placeholder="$t('profilehub.export.security.placeholder.password')"
              type="password"
            />
          </div>
        </section>
      </UiContainer>

      <UiActions centered large>
        <!-- <v-btn type="submit" rounded color="primary" outlined @click="onSubmitEmail">
          {{ $t('profilehub.export.cta.email') }}
        </v-btn> -->
        <v-btn :loading="exporting" :disabled="exporting" type="submit" rounded color="primary" @click="onSubmit">
          {{ $t('profilehub.export.cta.download') }}
        </v-btn>
      </UiActions>
    </div>
  </v-fade-transition>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { requiredIf, minLength } from 'vuelidate/lib/validators'
import { mapActions, mapState, mapGetters } from 'vuex'
import { clone } from '@/utils/utilities.util'
import { stringToSlug } from '@/utils/utilities.util'
import UiContainer from '@/components/UI/Container.vue'
import UiBanner from '@/components/UI/Banner.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiLabel from '@/components/UI/Label.vue'
import UiActions from '@/components/UI/Actions.vue'

export default {
  name: 'ProfileHubExport',
  components: {
    UiContainer,
    UiBanner,
    UiTitle,
    UiSubtitle,
    UiLabel,
    UiActions,
  },
  mixins: [validationMixin],
  data: () => ({
    name: '',
    format: '',
    segment: null,
    allCriteriaList: [],
    optin: false,
    password: '',
    passwordEnabled: false,
    updatingContacts: false,
    allCriteria: true,
    exporting: false,
    options: {},
    fileFormats: [
      {
        text: 'Excel (.xlsx)',
        value: 'xlsx',
      },
      {
        text: 'Comma-separated values (.csv)',
        value: 'csv',
      },
      {
        text: 'Tab-separated values (.tsv)',
        value: 'tsv',
      },
    ],
  }),
  async created() {
    this.setUpdating(true)
    await Promise.all([this.getSegments(), this.getCriteria(), this.getProfiles({ tableParams: this.options })])
    this.segment = this.currentSegmentName
      ? this.currentSegment
      : {
          ...this.currentSegment,
          name: this.$t('profilehub.export.subtitle'),
        }
    this.allCriteriaList = this.computedCriteria
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      currentBrand: state => state.backoffice.currentClient?.brandId,
      segments: state => state.profilehub.segments,
      currentSegment: state => state.profilehub.currentSegment,
      criterias: state => state.profilehub.criteria,
      profilesPagination: state => state.profilehub.profilesPagination,
    }),
    ...mapGetters({
      currentSegmentName: 'profilehub/currentSegmentName',
      totalProfiles: 'profilehub/totalProfiles',
    }),
    passwordError() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push(this.$t('error.required'))
      !this.$v.password.minLength &&
        errors.push(
          this.$t('error.minLength', {
            minLength: this.$v.password.$params.minLength.min,
          })
        )
      return errors
    },
    computedSegments() {
      const allProfiles = {
        id: 0,
        name: this.$t('profilehub.export.subtitle'),
        rules: {
          condition: 'and',
          filters: [],
        },
      }
      const clonedSegments = clone(this.segments)
      clonedSegments.unshift(allProfiles)
      return clonedSegments
    },
    computedExportData() {
      const data = {
        criterias: this.allCriteriaList,
        filename: stringToSlug(this.name),
        extension: this.format,
        optin: +this.optin,
        segmentId: this.currentSegment?.id || null,
        password: this.password,
      }
      return data
    },
    computedCriteria() {
      return this.criterias.map(c => c.label)
    },
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      getProfiles: 'profilehub/getProfiles',
      getSegments: 'profilehub/getSegments',
      getCriteria: 'profilehub/getCriteria',
      getExportProfiles: 'profilehub/getExportProfiles',
      getExportFile: 'profilehub/getExportFile',
    }),
    async updateCurrentProfiles() {
      this.updatingContacts = true
      await this.getExportProfiles(this.segment)
      this.updatingContacts = false
    },
    async onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.exporting = true
        try {
          await this.getExportFile(this.computedExportData)
        } catch {
          this.setAlert({
            color: 'error',
            text: this.$t('error.notification.default'),
          })
        }
        this.exporting = false
      }
    },
    onSubmitEmail() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        //to do
      }
    },
    allCriteriasSelectToggle() {
      if (!this.allCriteria) {
        this.allCriteriaList = []
      } else {
        this.allCriteriaList = this.computedCriteria
      }
    },
  },
  validations() {
    return {
      password: {
        required: requiredIf(() => this.passwordEnabled),
        minLength: minLength(5),
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.profile-hub-export {
  &__summary {
    @apply tw-sticky tw-top-6 tw-mb-6;

    z-index: 1;
  }

  &__section {
    @apply tw-mt-12;

    &--first {
      @apply tw-mt-0;
    }

    &--with-margin {
      @apply tw-mb-6;
    }

    &__grid {
      @apply tw-grid sm:tw-grid-cols-2 tw-gap-6 tw-w-full tw-mt-6;
    }
  }
}
</style>
